@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Visby';
  src: url('./fonts/VisbyRegular.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyBold';
  src: url('./fonts/VisbyBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('./fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DMSansBold';
  src: url('./fonts/DMSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
